// palette for This Is Vegas Casino
$bg-color: #010a48;
$text-color: #dbcbd6;
$primary: #c42324;
$success: #28a745;
$info: #3554a4;
$danger: #dc3545;

//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


#promos {
  img {
    width: 150% !important;
  }

}
